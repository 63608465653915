const getElementType = function (variable) {
    let element = variable.element;
    let type = variable.type;

    const options = variable.options ?? false;
    const custom = type.toLowerCase().includes("custom");
    const customType = custom ? type.substr(type.lastIndexOf(";") + 1) : false;

    if (options) {
        return "cm-select";
    }

    if (customType) {
        element = customType;
    }

    switch (element) {
        case "number":
        case "double":
            return "cm-input-number";
        case "mediumtext":
            return "cm-textarea";
        case "date":
        case "date_approx":
            return "cm-datepicker-2";
        case "slider":
            return "cm-slider";
        case "tinyint":
        case "varchar":
            return "cm-input-text";
        case "htmltext":
            return "cm-html-text";
        case "autocomplete":
            return "cm-autocomplete";
    }

    return "unknown-element-type";
}

export {
    getElementType
}
