<template>
  <div class="bg-gray-100 overflow-y-auto w-full">
    <div role="modules" class="bg-gray-100 shadow-inner">
      <nav class="h-full w-full text-base text-gray-800 text-left whitespace-nowrap">
        <button
          @click="$modal.show('patient-visit')"
          class="w-full flex items-center justify-between px-2 py-1 text-sm font-medium border-b border-transparent hover:bg-indigo-600 focus:outline-none focus:ring ring-indigo-200 hover:text-gray-100 cursor-pointer transition-all duration-100"
          exact-active-class="bg-indigo-600 text-gray-100 hover:bg-indigo-500 hover:text-gray-100"
        >
          {{ $t("patient.visits.new") }}
        </button>
        <router-link
          tag="button"
          :to="{ name: 'lab-module', params: { name: 'lab' }, query: { name: 'lab' } }"
          class="w-full flex items-center justify-between text-sm font-medium py-1"
          exact-active-class="bg-pink-400 text-gray-800"
        >
          <div class="w-full flex justify-between items-center text-left">
            <div class="flex-1 px-2">{{ $t("patient.lab.lab") }}</div>
          </div>
        </router-link>
        <router-link
          tag="button"
          :to="{ name: 'therapy-module', params: { name: 'therapy' }, query: { name: 'therapy' } }"
          class="w-full flex items-center justify-between text-sm font-medium py-1 hover:bg-indigo-600 focus:outline-none focus:ring ring-indigo-200 hover:text-gray-100 cursor-pointer transition-all duration-100"
          exact-active-class="bg-indigo-600 text-gray-100 hover:bg-indigo-500 hover:text-gray-100"
        >
          <div class="w-full flex justify-between items-center text-left">
            <div class="flex-1 px-2">{{ $t("patient.therapy.therapy") }}</div>
          </div>
        </router-link>
        <router-link
          v-for="(tab, key) in sortedtabs"
          tag="button"
          :to="{ name: 'patient-module', params: { name: tab.name }, query: { name: tab.name } }"
          :key="key"
          class="w-full flex items-center justify-between pl-1 pr-1 py-1 text-sm font-medium border-b border-transparent hover:bg-indigo-600 focus:outline-none focus:ring ring-indigo-200 hover:text-gray-100 cursor-pointer transition-all duration-100 overflow-visible"
          exact-active-class="bg-indigo-600 text-gray-100 hover:bg-indigo-500 hover:text-gray-100"
        >
          <div class="w-full flex justify-between items-center text-left">
            <span class="flex-1">{{ tab.translation.label }}</span>
            <span :class="{ 'text-gray-300': tab.cnt === 0 }">{{ tab.cnt }}</span>
          </div>
          <div>
            <button class="border rounded-sm p-1 ml-1" @click.stop="createNewRegistration(tab.name)">+</button>
          </div>
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ModuleListVertical",
  computed: {
    ...mapState("patient", {
      tabs: state => state.tabs,
      sortedtabs() {
        if (!this.tabs) return [];

        const tabs = JSON.parse(JSON.stringify(this.tabs));
        return tabs.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      },
    }),
  },
  methods: {
    async createNewRegistration(name) {
      await this.$store.dispatch("moduledata/load", name);
      await this.$store.dispatch("moduledata/resetFormdata", this.name);

      const layout = this.$store.getters["moduledata/getFormLayout"](name);
      const structure = {
        layout: layout,
        data: null,
        id: null,
      };

      this.$modal.show("form", structure);
    },
  },
};
</script>

<style>
</style>