<template>
  <div>
    <div class="bg-white flex justify-between">
      <div class="flex justify-start">
        <div class="flex flex-row px-2 content-center items-center">
          <router-link tag="button" :to="{ name: 'user-start' }">
            <div class="w-8 text-gray-500 hover:text-gray-800 transition-all duration-100">
              <svg
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                />
              </svg>
            </div>
          </router-link>
        </div>
        <div class="px-4 py-2 text-base text-indigo-500 flex shadow">
          <div class="flex-col mr-2">
            <div>
              <span class="font-medium">{{ name }}</span>
              <span class="mx-1">-</span>
              <span>{{ ssn }}</span>
              <span v-if="compos3Id" class="ml-2 text-gray-500">({{ compos3Id }})</span>
            </div>
          </div>
        </div>
      </div>
      <div class="px-4 py-2 text-base text-gray-800 flex flex-col shadow">
        <div>{{ info.bas_consent }}</div>
      </div>
    </div>

    <div class="bg-gray-700 text-white flex px-2 py-2 text-sm">
      <div>
        <router-link
          :to="{ name: 'patient-basedata' }"
          exact-active-class="bg-gray-600"
          class="px-2 mx-1 py-1 rounded-lg hover:bg-gray-600 cursor-pointer transition-all duration-100"
          >{{ $t("patient.menu.basedata") }}</router-link
        >
      </div>
      <div>
        <router-link
          :to="{ name: 'patient-visits' }"
          exact-active-class="bg-gray-600"
          class="px-2 mx-1 py-1 rounded-lg hover:bg-gray-600 cursor-pointer transition-all duration-100"
          >{{ $t("patient.menu.visits") }}</router-link
        >
      </div>
      <div>
        <router-link
          :to="{ name: 'patient-graphs' }"
          exact-active-class="bg-gray-600"
          class="px-2 mx-1 py-1 rounded-lg hover:bg-gray-600 cursor-pointer transition-all duration-100"
          >{{ $t("patient.menu.overview") }}</router-link
        >
      </div>
      <div v-if="pmRegistrations && pmRegistrations.result">
        <router-link
          :to="{ name: 'patient-pm-registrations' }"
          exact-active-class="bg-gray-600"
          class="px-2 mx-1 py-1 rounded-lg bg-yellow-400 hover:bg-gray-600 cursor-pointer transition-all duration-100"
          >{{ $t("patient.menu.pm_registrations") }}</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PatientHeader",
  props: ["info", "pmRegistrations"],
  computed: {
    name() {
      return `${this.info?.first_name} ${this.info?.last_name}`;
    },
    ssn() {
      return `${this.info?.ssn}`;
    },
    compos3Id() {
      return process.env.NODE_ENV === "development" && this.info?.compos3_id ? `${this.info?.compos3_id}` : null;
    },
  },
};
</script>
