<template>
  <div class="flex-col">
    <div class="text-lg font-semibold mb-1">{{ translation.label }}</div>
    <div v-for="(d, key) in data" :key="key" class="flex justify-between">
      <div>{{ localDate(d.observation_date) }}</div>
      <div>
        <cm-button size="small" visual="danger" @click="editModuleData(d)">{{ $t("button.edit") }}</cm-button>
      </div>
    </div>
    <div>
      <cm-button size="small" @click="createModuleData">{{ $t("button.create") }}</cm-button>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/utils/date";

export default {
  name: "VisitModuleHistory",
  props: ["modulename", "translation", "data"],
  methods: {
    localDate(date) {
      return dateFormat(date);
    },
    editModuleData(d) {
      this.$emit("editModuleData", { formname: this.modulename, id: d.id });
    },
    createModuleData() {
      this.$emit("createModuleData", { formname: this.modulename });
    },
  },
};
</script>

<style>
</style>