<template>
  <div>
    <transition name="fade">
      <div v-if="loaded" class="w-full" v-show="showSection">
        <div v-if="element.elements" :class="[layoutDirection, sectionIndentation]">
          <module-section
            v-for="(el, key) in element.elements"
            :key="key"
            :element="el"
            :formname="formname"
            :formid="formid"
            :parentdisabled="isDisabled"
          />
        </div>
        <div v-else>
          <validation-provider
            v-if="element && element.element !== 'htmltext'"
            v-slot="validation"
            :rules="validationRules"
            :name="element.translation.label"
          >
            <div>
              <label class="block text-gray-800 text-sm font-semibold mb-0" :for="element.name">
                <span>{{ element.translation.label }}</span>
                <span v-if="validationRules.required" class="text-red-500 ml-1">*</span>
              </label>

              <component
                :ref="element.name"
                :id="element.name"
                :is="elementType"
                v-model="fieldValue"
                v-bind="elementProps"
              />

              <p class="ml-2 text-red-500 italic text-sm" v-show="validation.errors.length > 0">
                {{ validation.errors[0] }}
              </p>
            </div>
          </validation-provider>
          <component
            v-if="element.element === 'htmltext'"
            :ref="element.name"
            :id="element.name"
            :is="elementType"
            :value="fieldValue"
            v-bind="elementProps"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * section display options
 *  - hide / show : show or hide the entire section. when hiding, set all values within to null
 *  - disable / enable : disable or enable all elements in the section. on disable, set all values to null
 */

import { mapState } from "vuex";
import { ValidationProvider, extend } from "vee-validate";
import { getElementType } from "@/utils/form.js";

import { required, oneOf, min_value, max_value, between, max } from "vee-validate/dist/rules";

extend("required", { ...required });
extend("oneOf", { ...oneOf });
extend("min_value", { ...min_value });
extend("max_value", { ...max_value });
extend("between", { ...between });
extend("max", { ...max });

export default {
  name: "ModuleSection",
  props: ["formname", "formid", "element", "iteration", "parentdisabled"],
  components: {
    ValidationProvider,
  },
  data() {
    return {
      loaded: false,
      condition: null,
      effect: null,
      showSection: true,
      disabled: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.setUpRules();
      this.loaded = true;
      this.invokeConditionalRendering();
    });
  },
  watch: {
    formdata: {
      immediate: true,
      deep: true,
      handler() {
        this.invokeConditionalRendering();
      },
    },
  },
  computed: {
    ...mapState("moduledata", {
      allforms: state => state.form,
    }),
    formdata() {
      return this.allforms[this.formname];
    },
    fieldValue: {
      set(v) {
        if (this.formname == "visit" && this.element.name === "date") {
          this.$emit("updateSelectedVisitDate", { value: v });
        }

        console.log(this.formname, this.element.name, v);
        this.$store.dispatch("moduledata/setFormFielddata", {
          formname: this.formname,
          field: this.element.name,
          data: v,
        });
      },
      get() {
        return this.$store.getters["moduledata/getFormFieldData"](this.formname, this.element.name);
      },
    },
    elementType() {
      if (!this.element) return;

      return getElementType(this.element);
    },
    elementProps() {
      if (this.elementType === "cm-select") {
        return {
          options:
            typeof this.element.options === "object" ? Object.values(this.element.options) : this.element.options,
          disabled: this.parentdisabled,
        };
      }
      if ("cm-autocomplete" === this.elementType) {
        return {
          url: this.element.url,
          disabled: this.parentdisabled,
        };
      }
      if ("cm-input-number" === this.elementType) {
        return {
          min: this.element.min ? +this.element.min : null,
          max: this.element.max ? +this.element.max : null,
          disabled: this.parentdisabled,
        };
      }
      if ("cm-html-text" === this.elementType) {
        return {
          html: this.element.html,
        };
      }

      return {
        disabled: this.parentdisabled,
      };
    },
    layoutDirection() {
      return {
        "flex-col": this.element.type === "VerticalLayout",
        "flex space-x-2": this.element.type === "HorizontalLayout",
      };
    },
    sectionIndentation() {
      return {
        "mb-2":
          ["VerticalLayout", "HorizontalLayout"].includes(this.element.type) &&
          this.element.elements &&
          this.element.elements.length > 2,
      };
    },
    validationRules() {
      let options;
      if (typeof this.element.options === "object") {
        options = Object.values(this.element.options).map(d => {
          return d.value;
        });
      } else {
        options = this.element.options
          ? this.element.options.map(d => {
              return d.value;
            })
          : null;
      }

      const min = this.element.min ?? null;
      const max = this.element.max ?? null;

      let tempRules = {
        required: this.element.required,
        oneOf: options ? options : false,
        between: min !== null && max !== null ? { min: min, max: max } : false,
        //   this.element.min !== null && this.element.max !== null
        //     ? { min: +this.element.min, max: +this.element.max }
        //     : null,
        // min_value: min,
        // max_value: max,
      };

      let rules = {};
      Object.keys(tempRules).forEach(prop => {
        if (tempRules[prop] !== null) {
          rules[prop] = tempRules[prop];
        }
      });

      return rules;
    },
    isDisabled() {
      return this.disabled;
    },
  },
  methods: {
    invokeConditionalRendering() {
      if (!this.condition) return;

      const change = Object.entries(this.formdata["data"]["data"]);
      const condition = Object.entries(this.condition);

      const changeCondition = condition.filter(cond => {
        return change.find(ch => {
          return cond[0] === ch[0];
        });
      });

      const hasEmptyValueRule = condition.filter(d => {
        return d[1].method === "emptyValue";
      });

      if (change.length === 0 && hasEmptyValueRule.length > 0) {
        //special case for empty value which needs to be invoked on empty formdata
        try {
          if (this[hasEmptyValueRule[0][1].method](hasEmptyValueRule[0][1].values, null)) {
            const options = this.getSectionDisplayOptions();

            this.showSection = options.display;

            this.element.elements.forEach(d => {
              this.updateFormDataValue(d.name, null);
            });
          }
        } catch (e) {
          console.error(e);
        }
      }

      if (changeCondition.length) {
        const changeValue = change.find(c => {
          return c[0] === changeCondition[0][0];
        });

        if (changeValue) {
          let v = changeValue[1];
          let c = changeCondition[0][1];

          try {
            if (c.method) {
              //rule fulfilled
              if (this[c.method](c.values, v)) {
                const options = this.getSectionDisplayOptions();

                this.showSection = options.display;

                this.element.elements.forEach(d => {
                  this.updateFormDataValue(d.name, null);
                });
              } else {
                this.showSection = true;
                this.disabled = false;
              }
            } else {
              //rule fulfilled
              if (this[c](v)) {
                const options = this.getSectionDisplayOptions();

                this.showSection = options.display;

                this.element.elements.forEach(d => {
                  this.updateFormDataValue(d.name, null);
                });
              } else {
                this.showSection = true;
                this.disabled = false;
              }
            }
          } catch (e) {
            console.error(e, " conditional redering failed. possibly missing method for section");
          }
        }
      }
    },
    getSectionDisplayOptions() {
      this.disabled = ["disable"].includes(this.effect) ? true : false;

      return {
        display: ["hide"].includes(this.effect) ? false : true,
        disable: ["disable"].includes(this.effect) ? true : false,
      };
    },
    updateFormDataValue(field, val) {
      this.$store.dispatch("moduledata/setFormFielddata", {
        formname: this.formname,
        field: field,
        data: val,
      });
    },
    setUpRules() {
      if (!this.element.rule) return;

      this.effect = this.element.rule.effect;
      this.condition = this.element.rule.condition;
    },
    missingValue(v) {
      return !v;
    },
    numericValue(compareValues, v) {
      return compareValues.map(d => +d).includes(+v);
    },
    anyValue(compareValues, v) {
      return v !== null;
    },
    emptyValue(compareValues, v) {
      return v === null;
    },
  },
};
</script>
