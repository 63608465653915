<template>
  <div class="h-full relative">
    <module-form-header :label="label" :patient="info" :data="{}" @cancel="cancel" class="shadow-md" />

    <validation-observer :ref="'formobserver'" v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(submitPost)" class="flex flex-col max-h-screen -mt-10 pt-10">
        <div class="overflow-y-auto px-4 shadow-md pb-10 pt-4">
          <module-section
            v-for="(element, key) in layout.elements"
            :formname="name"
            :key="key"
            :element="element"
            :iteration="key"
            :formid="data && data.id ? data.id : null"
          />
        </div>

        <transition name="fade">
          <div class="bg-gray-800 text-gray-50 text-xs" v-if="errors">
            <div class="h-1 bg-red-500"></div>
            <div class="p-2 flex justify-between">
              <details>
                <summary>{{ $t("form.error.summarytext") }}</summary>
                <div v-for="(error, key) in errors" :key="key" class="px-1 hover:bg-gray-500 rounded">
                  <span class="mr-1">{{ getTranslation(error.name) }}:</span>
                  <span v-if="error.type === 'option'"
                    >{{
                      $t("validation.error.option", {
                        value: error.value,
                        acceptable: error.acceptable.map(d => d.translations.sv).join(", "),
                      })
                    }}
                  </span>
                  <span v-if="error.type === 'minimum'">{{
                    $t("validation.error.minimum", { value: error.value, acceptable: error.acceptable })
                  }}</span>
                  <span v-if="error.type === 'maximum'">
                    {{ $t("validation.error.maximum", { value: error.value, acceptable: error.acceptable }) }}
                  </span>
                </div>
              </details>
              <div>
                <cm-button size="small" @click="errors = null">{{ $t("button.close") }}</cm-button>
              </div>
            </div>
          </div>
        </transition>

        <module-form-menu
          :invalid="invalid"
          @cancel="cancel"
          @delete-post="deletePost(data && data.id ? data.id : null)"
          :formid="data ? data.id : null"
        />
      </form>

      <module-form-footer :data="data" />
    </validation-observer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModuleFormMenu from "@/components/patient/module/ModuleFormMenu";
import ModuleFormHeader from "@/components/patient/module/ModuleFormHeader";
import ModuleFormFooter from "@/components/patient/module/ModuleFormFooter";
import ModuleSection from "@/components/patient/module/ModuleSection";
import { ValidationObserver, localize } from "vee-validate";

function loadVeeValidateLocale(code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    localize(code, locale);
  });
}

export default {
  name: "ModuleForm",
  props: ["name", "label", "layout", "data", "id"],
  components: {
    ModuleFormHeader,
    ModuleFormFooter,
    ModuleFormMenu,
    ModuleSection,
    ValidationObserver,
  },
  data() {
    return {
      errors: null,
    };
  },
  beforeMount() {
    loadVeeValidateLocale(this.userLocale ?? "sv");
  },
  async mounted() {
    this.$nextTick(() => {
      this.$refs.formobserver.reset();
    });
  },
  methods: {
    getTranslation(name) {
      const el = this.layout.elements.find(d => d.name === name);
      return el ? el.translation.label : name;
    },
    cancel() {
      this.$modal.hide("form");
      this.$emit("cancel");
    },
    deletePost(id) {
      this.$store.dispatch("patient/removePost", { formname: this.name, id }).then(() => {
        this.pendingSave = false;
        this.$modal.hide("form");
      });
    },
    submitPost() {
      this.errors = null;
      this.pendingSave = true;

      const data = this.$store.getters["moduledata/getFormData"](this.name);
      // const data = this.$store.state["moduledata"]["form"];

      this.$store
        .dispatch("patient/submitPost", {
          id: this.id,
          formname: this.name,
          data: data.data,
        })
        .then(() => {
          this.pendingSave = false;
          this.$modal.hide("form");
        })
        .catch(e => {
          this.errors = e.response?.data?.errors ?? [];
        });
    },
  },
  computed: {
    ...mapState("patient", {
      info: state => state.info,
    }),
  },
};
</script>
