<template>
  <div class="h-full relative" v-if="loaded">
    <module-form-header :label="$t('patient.visits.label')" :patient="info" :data="{}" />

    <div v-if="visitModules" class="mb-10 flex justify-between">
      <div :class="{ 'w-1/2': historicData }">
        <validation-observer :ref="'formobserver'" v-slot="{ handleSubmit, invalid }">
          <form
            ref="visit-form"
            @submit.prevent="handleSubmit(submitVisit)"
            class="flex flex-col max-h-screen -mt-10 pt-10"
          >
            <div class="overflow-y-auto px-4 shadow mb-12 pb-10 pt-4">
              <div v-for="(visitmodule, key) in editableModules" :key="key">
                <div v-if="visitmodule.name !== 'visit'" class="mt-4 text-lg">{{ visitmodule.translation.label }}</div>
                <module-section
                  v-for="(element, key) in visitmodule.structure.elements"
                  :key="key"
                  :formname="visitmodule.name"
                  :element="element"
                  @updateSelectedVisitDate="updateSelectedVisitDate"
                />
              </div>
            </div>
            <module-form-menu
              :invalid="invalid"
              :formid="data ? data.id : null"
              class="absolute bottom-0 left-0"
              @cancel="closeVisit"
            />
          </form>
        </validation-observer>
      </div>

      <div v-if="historicData" class="w-1/2 flex flex-col max-h-screen -mt-10 pt-10 pb-10">
        <div class="p-3 text-lg border-b flex space-x-5">
          <div>
            <span>{{ $t("visit.moduleregistration.history") }}</span>
          </div>
          <div>
            <label class="flex mx-1" for="historic-data-period"
              >{{ $t("visit.moduleregistration.history_period") }}:
              <cm-select
                v-model="period"
                :options="[
                  { value: 1, label: 1 },
                  { value: 3, label: 3 },
                  { value: 6, label: 6 },
                ]"
                :value="period"
                nameProp="label"
              />
            </label>
          </div>
        </div>
        <div class="overflow-y-auto px-4 pt-4">
          <visit-module-history
            v-for="(visitmodule, key) in historicData"
            :key="key"
            :modulename="visitmodule.name"
            :data="visitmodule.data"
            :translation="visitmodule.translation"
            @editModuleData="editModuleData"
            @createModuleData="createModuleData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ModuleFormHeader from "@/components/patient/module/ModuleFormHeader";
import ModuleSection from "@/components/patient/module/ModuleSection";
import ModuleFormMenu from "@/components/patient/module/ModuleFormMenu";
import VisitModuleHistory from "@/components/patient/visit/VisitModuleHistory";
import { compareAsc, isSameDay, sub } from "date-fns";

import { ValidationObserver, localize } from "vee-validate";

function loadVeeValidateLocale(code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    localize(code, locale);
  });
}

export default {
  name: "VisitForm",
  props: ["id"],
  components: {
    ModuleSection,
    ModuleFormHeader,
    ModuleFormMenu,
    ValidationObserver,
    VisitModuleHistory,
  },
  data() {
    return {
      loaded: false,
      type: null,
      selectedVisitDate: null,
      period: 3,
    };
  },
  beforeMount() {
    loadVeeValidateLocale(this.userLocale ?? "sv");
  },
  created() {
    this.loadModulesForVisitType();
  },
  mounted() {},
  computed: {
    data() {
      return null;
    },
    ...mapGetters("visit", ["visits"]),
    ...mapState("meta", {
      visitTypes: state => state.visitTypes,
      visitModules: state => state.visitModules.filter(d => d.structure),
    }),
    ...mapState("patient", {
      info: state => state.info,
    }),
    selectableTypes() {
      if (!this.visitTypes) {
        return [];
      }

      return this.visitTypes.map(d => {
        return {
          name: d.name,
          value: d.value,
        };
      });
    },
    historicData() {
      const maxDate = this.selectedVisitDate ? sub(this.selectedVisitDate, { months: this.period }) : new Date();

      return this.visitModules
        .filter(d => {
          return d.settings.find(d => {
            return d?.display_history && d.display_history == "1";
          });
        })
        .map(d => {
          const data = d.data.filter(d => {
            const obsdate = new Date(d.observation_date);
            console.log(compareAsc(obsdate, maxDate));
            return this.selectedVisitDate !== null
              ? (compareAsc(obsdate, maxDate) === 1 && compareAsc(this.selectedVisitDate, obsdate) === 1) ||
                  isSameDay(this.selectedVisitDate, obsdate)
              : d;
          });
          return {
            name: d.name,
            translation: d.translation,
            data: data,
          };
        });
    },
    editableModules() {
      return this.visitModules.filter(d => {
        return d.settings.find(v => {
          return v?.direct_edit && v?.direct_edit == "1";
        });
      });
    },
  },
  methods: {
    updateSelectedVisitDate({ value }) {
      this.selectedVisitDate = value;
    },
    async editModuleData({ formname, id }) {
      const moduledata = this.historicData.filter(d => d.name === formname).find(d => d.data.find(v => +v.id === +id));
      const rowdata = moduledata.data.find(d => +d.id === +id);

      await this.$store.dispatch("moduledata/setFormdata", { formname: formname, data: rowdata });
      const structure = {
        layout: this.$store.getters["moduledata/getFormLayout"](formname),
        data: rowdata,
        id: id,
      };

      this.$modal.show("form", structure);
    },
    async createModuleData({ formname }) {
      await this.$store.dispatch("moduledata/resetFormdata", { formname: formname, data: null });
      const structure = {
        layout: this.$store.getters["moduledata/getFormLayout"](formname),
        data: null,
        id: null,
      };

      this.$modal.show("form", structure);
    },
    async loadModulesForVisitType() {
      await this.$store.dispatch("meta/modulesForVisitType");
      await this.$store.dispatch("visit/loadAll");

      if (this.id) {
        const visit = await this.$store.getters["visit/visitById"](this.id);

        await this.$store.dispatch("moduledata/setFormdata", {
          data: { data: { ...visit } },
          formname: "visit",
        });

        await visit.module_registrations.forEach(d => {
          this.$store.dispatch("moduledata/setFormdata", {
            data: { id: d.id, data: d.data },
            formname: d.module.name,
          });
        });
        this.loaded = true;
      } else {
        this.loaded = true;
      }
    },
    submitVisit() {
      this.$store.dispatch("visit/submit", this.id).then(() => {
        this.$modal.hide("patient-visit");
      });
    },
    closeVisit() {
      this.$modal.hide("patient-visit");
    },
  },
};
</script>
