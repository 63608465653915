<template>
  <div class="h-full w-full bg-gray-50 flex flex-col" v-if="info">
    <patient-header :info="info" :pmRegistrations="pmRegistrations" />

    <div class="flex-1 h-full w-full overflow-y-auto grid grid-cols-12">
      <div class="col-span-2 xl:col-span-1">
        <module-list-vertical />
      </div>
      <div class="col-span-10 xl:col-span-11">
        <splitpanes horizontal @resized="paneResize" @ready="panesReady">
          <pane class="h-full flex" min-size="10" :size="panesize[0]" ref="northpane">
            <router-view
              name="north"
              :key="$route.fullPath"
              class="h-full flex-1"
              :h="northPaneHeight"
              @hideSouthPane="hideSouthPane"
            ></router-view>
          </pane>
          <pane v-if="showSouthPane" class="w-full" min-size="10" :size="panesize[1]" ref="southpane">
            <router-view name="south" :key="$route.fullPath" :height="southPaneHeight"></router-view>
          </pane>
        </splitpanes>
      </div>
    </div>

    <modal
      name="patient-consent"
      :clickToClose="false"
      :height="'auto'"
      classes="bg-white rounded-lg shadow flex flex-col justify-between"
    >
      <patient-consent :info="info" />
    </modal>

    <modal
      name="patient-visit"
      @before-open="beforeVisitOpen"
      :click-to-close="true"
      :scrollable="false"
      :resizable="true"
      :draggable="true"
      :adaptive="true"
      :reset="true"
      :pivotY="0.1"
      :height="'auto'"
      :width="'65%'"
      classes="bg-white rounded shadow-md transform transition-all object-contain overflow-visible"
    >
      <visit-form :id="visitId" />
    </modal>
    <modal
      name="form"
      @before-open="beforeFormOpen"
      :click-to-close="false"
      :scrollable="false"
      :resizable="true"
      :draggable="true"
      :adaptive="false"
      :reset="true"
      :pivotY="0"
      :height="'auto'"
      classes="bg-white rounded shadow-md transform transition-all object-contain overflow-visible"
    >
      <module-form
        v-if="registration"
        :id="registration.id"
        :name="registration.layout.name"
        :label="registration.layout.translation.label"
        :layout="registration.layout.structure"
        :data="registration.data"
      />
    </modal>
  </div>
</template>

<script>
import { Splitpanes, Pane } from "splitpanes";
import { mapState } from "vuex";
import PatientHeader from "@/components/patient/PatientHeader";
import ModuleForm from "@/components/patient/module/ModuleForm";
import PatientConsent from "@/components/patient/consent/PatientConsent";
import VisitForm from "@/components/patient/visit/VisitForm";
import ModuleListVertical from "../components/patient/module/ModuleListVertical.vue";

export default {
  name: "Patient",
  components: {
    PatientHeader,
    Splitpanes,
    Pane,
    ModuleForm,
    PatientConsent,
    VisitForm,
    ModuleListVertical,
  },
  data() {
    return {
      modalHeight: 0,
      panesize: [70, 30],
      registration: null,
      visitId: null,
      showNorthhPane: true,
      showSouthPane: true,
      northPaneHeight: 0,
      southPaneHeight: 0,
    };
  },
  created() {
    this.showSouthPane = this.$route?.matched[1]?.components?.south;
    this.modalHeight = window.innerHeight - 20;
    if (this.info === null) {
      this.reload();
    }
  },
  computed: {
    ...mapState("patient", {
      info: state => state.info,
      pmRegistrations: state => state.pmRegistrations,
    }),
  },
  methods: {
    async reload() {
      await this.$store.dispatch("patient/getInfo");
    },
    panesReady() {
      this.$nextTick(() => {
        this.northPaneHeight = this.$refs.northpane.$el.clientHeight;
        this.southPaneHeight = this.$refs?.southpane?.$el?.clientHeight || 0;
      });
    },
    paneResize(panesize) {
      this.panesize = [panesize[0].size, panesize[1].size];
      this.northPaneHeight = this.$refs.northpane.$el.clientHeight;
      this.southPaneHeight = this.$refs?.southpane?.$el?.clientHeight || 0;
    },
    beforeFormOpen(event) {
      this.registration = event.params;
    },
    async beforeVisitOpen(event) {
      this.visitId = event.params;
    },
    hideSouthPane() {
      this.showSouthPane = false;
    },
  },
};
</script>
