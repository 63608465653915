<template>
  <div class="shadow-inner py-1 px-2 bg-gray-200 text-black">
    <div class="font-normal text-xs flex space-x-2 justify-end">
      <div v-if="data && data.created_at">
        {{ $t("form.created_at") }}
        <span class="font-normal">{{ formatDateAndTime(data.created_at) || "-" }}</span>
      </div>
      <div v-if="data && data.updated_at && data.updated_at !== data.created_at">
        {{ $t("form.updated_at") }}
        <span class="font-normal">{{ formatDateAndTime(data.updated_at) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { dateAndTimeFormat } from "@/utils/date";

export default {
  name: "ModuleFormFooter",
  props: ["data"],
  methods: {
    formatDateAndTime(date) {
      return dateAndTimeFormat(date);
    },
  },
};
</script>

<style>
</style>