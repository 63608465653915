<template>
  <div ref="menu" class="w-full flex p-2 justify-between bg-gray-200">
    <div class="flex space-x-1">
      <cm-button visual="success" size="medium" type="submit" :disabled="invalid || pendingSave">{{
        $t("button.save")
      }}</cm-button>

      <cm-button class="mr-2" visual="danger" size="medium" :disabled="!formid" @click.prevent="confirmDelete">{{
        $t("button.delete")
      }}</cm-button>
    </div>

    <div class="flex">
      <cm-button class="mr-2" size="medium" @click.prevent="$emit('cancel')">{{ $t("button.cancel") }}</cm-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModuleFormMenu",
  props: ["formid", "invalid", "pendingSave"],
  methods: {
    confirmDelete() {
      this.$emit("delete-post");
    },
  },
};
</script>
